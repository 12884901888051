import React from 'react'

const Contact = () => {
  return (
    <div>
      <h1 className="w-full text-center mt-8 mb-8 playfair text-5xl font-bold">Contact Us!</h1>
    </div>
  )
}

export default Contact